import {SPONSORED_BLOCK_MAX_LINKS} from "../config/sponsored-links.js";

const SponsorBlock = () => ({
    maxLinks: SPONSORED_BLOCK_MAX_LINKS,
    links: [],

    init() {
        this.$watch("$store.SponsorBlock.isReady", () => {
            this.links = this.$store.SponsorBlock.getLinks();
        });
    }
});

export default SponsorBlock;
