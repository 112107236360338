/*

Medium layout

- Shows 3 columns
- The "About the Page" block from column 4 is moved to the bottom of column 2
- All other blocks of column 4 are place below the blocks of column 3
- The "About the Administrator" block is moved to the bottom of column 3

Small layout

- Shows 2 columns
- All blocks from column 3 and 4 are divided over columns 1 and 2 (below the original ones)
- The "About the Page" block is moved to the bottom of column 1
- The "About the Administrator" block is moved to the bottom of column 2

Tiny layout

- Shows 1 column
- Start with the blocks on column 1 and 2 displayed alternately
- Followed by the blocks from column 3, which are followed by the regular blocks of column 4
- On the bottom display the "About the Page" and "About the Administrator" blocks

*/
import $ from "jquery";

import {cycle, roundRobin} from "../../rainbow/js/blocks";
import * as base from "../../rainbow/js/responsiveness";

function reorderBlocks(blockContainer, targetContainer) {
    const sections = targetContainer.find(".section:not(.section-wide)");

    if (base.largeBreakpoint.matches) {
        sections.eq(0).append(blocks[0]);
        sections.eq(1).append(blocks[1]);
        sections.eq(2).append(blocks[2]);

        columns.eq(3).prepend(aboutAuthor).prepend(aboutPage);
        columns.eq(3).append(blocks[3]);

    } else if (base.mediumBreakpoint.matches) {
        sections.eq(0).append(blocks[0]);
        sections.eq(1).append(blocks[1]);
        sections.eq(2).append(blocks[2]).append(blocks[3]);

        sections.eq(1).append(aboutPage);
        sections.eq(2).append(aboutAuthor);

    } else if (base.smallBreakpoint.matches) {
        sections.eq(0).append(blocks[0]);
        sections.eq(1).append(blocks[1]);

        // blocks from 3, followed by blocks from 4, distributed over 1 and 2
        const target = cycle(sections);
        for (const block of blocks[2]) {
            target.next().value.append(block);
        }
        for (const block of blocks[3]) {
            target.next().value.append(block);
        }

        sections.eq(0).append(aboutPage);
        sections.eq(1).append(aboutAuthor);

    } else {
        // blocks from 1 and 2 interleaved
        sections.append([...roundRobin([blocks[0], blocks[1]])]);

        sections.append(blocks[2]).append(blocks[3]);
        sections.append(aboutPage).append(aboutAuthor);
    }
}

let columns, blocks, aboutPage, aboutAuthor;

export function initBlockReordering() {
    if (!$(".responsive-column").length) {
        return;
    }

    columns = $(".responsive-column .section:not(.section-wide)").add(".column4 .section");
    blocks = columns.map((i, column) => $(".block", column).siblings().not(".about-page, .about-author"));

    aboutPage = columns.eq(3).find(".about-page");
    aboutAuthor = columns.eq(3).find(".about-author");

    if (blocks.length > 3) {
        base.initBlockReordering(() => {
            base.inspectPage(reorderBlocks);
        });
    }
}
