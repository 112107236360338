import $ from "jquery";

$(() => {
    $("#js-choose-existing").on("click", e => {
        e.preventDefault();
        $(".show-existing").removeClass("hidden");
        $(".show-new").addClass("hidden");
    });


    $(".thema-list.show-existing a").on("click", e => {
        e.preventDefault();

        const field = $("input[name='choose-thema-thema_code']");
        const code = $(e.target).text();

        field.val(code);
        field.parents("form").submit();
    });

    const companyType = $("#id_registration-company_type");
    function toggleCompanyBlock() {
        $(".block.company").toggleClass("hidden", companyType.val() === "np");
    }
    companyType.on("change", toggleCompanyBlock);
    toggleCompanyBlock();
});
