import {loadBanner} from "../../../rainbow/js/banners";
import {hasOptIn, loadScript} from "../../../rainbow/js/consent";
import WidgetBase from "../../../rainbow/js/widgets/base";

export class AdSenseWidget extends WidgetBase {
    constructor(element) {
        super(element);

        if (!hasOptIn("Use limited data to select advertising")) {
            // No cookie consent has been give, do not show AdSense.
            return;
        }

        // When a AdSense publisher ID has been filled in, we use it in 50%
        // of page views.
        const publisherId = this.element.find("ins").data("adClient");
        if (publisherId && this.flipCoin()) {
            this.loadUserAdSense();
        } else {
            this.loadAdSense();
        }
    }

    flipCoin() {
        return Math.random() < 0.5;
    }

    loadUserAdSense() {
        this.element.find("ins").addClass("adsbygoogle").css({display: "block", maxHeight: "200px"});

        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});

        loadScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
    }

    loadAdSense() {
        loadBanner("#google-adsense-placeholder", "r1");
    }
}
