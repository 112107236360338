import Alpine from "alpinejs";
import $ from "jquery";

import "../profile/js/account-button";
import "../rainbow/js/background-color";
import "../rainbow/js/banners";
import {minimizeBlocks} from "../rainbow/js/blocks";
import {checkConsentContent} from "../rainbow/js/consent";
import "../rainbow/js/csrf";
import "../rainbow/js/index";
import "../rainbow/js/link-masking";
import {loadWidgets} from "../rainbow/js/load-widgets";
import "../rainbow/js/party-mode";
import "../rainbow/js/preview-bar";
import "../rainbow/js/refresh";
import {addScrollHandler, getBlockContainer} from "../rainbow/js/responsiveness";
import "../rainbow/js/tracking/tracking";
import "../rainbow/scss/application-tailwind.scss";
import "./js/index";
import "./js/request-new-thema";
import {initBlockReordering} from "./js/responsiveness";
import "./js/thema-list-filter";
import * as widgets from "./js/widgets/all";
import "./scss/application.scss";

$(function () {
    initBlockReordering();
    addScrollHandler();
    loadWidgets(widgets);

    const blockContainer = getBlockContainer();
    if (blockContainer) {
        minimizeBlocks();
    }

    checkConsentContent($("body"));
});

window.Alpine = Alpine;
Alpine.start();
