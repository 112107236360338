import Alpine from "alpinejs";

import getConfig from "../../../umbrella/js/config";
import {SPONSORED_BLOCK_MAX_LINKS, SPONSORED_BLOCK_PER_PAGE} from "../config/sponsored-links";

Alpine.store("SponsorBlock", {
    links: [],
    linksPerBlock: null,
    isReady: false,

    init() {
        if (getConfig("txelBranch", null) !== null) {
            const params = {
                application: "sparkle",
                amount: SPONSORED_BLOCK_MAX_LINKS * SPONSORED_BLOCK_PER_PAGE,
                branch: getConfig("txelBranch"),
                adult_content: getConfig("adultContent", false),
            };

            fetch(getConfig("txelUrl"), params).then(async (data) => {
                const result = await data.json();
                this.links = result;
                this.linksPerBlock = Math.min(Math.round(result.length / SPONSORED_BLOCK_PER_PAGE), SPONSORED_BLOCK_MAX_LINKS);
                this.isReady = true;
            });
        }
    },

    getLinks() {
        const result = this.links.slice(0, this.linksPerBlock);
        this.links = this.links.slice(this.linksPerBlock);

        return result;
    }
});
