import $ from "jquery";

import {replaceContent, hasAllOptIns} from "../../../rainbow/js/consent";
import WidgetBase from "../../../rainbow/js/widgets/base";
import "../../scss/widgets/banner.scss";

export class ThemaBannerWidget extends WidgetBase {
    constructor(element) {
        super(element);

        if (!hasAllOptIns()) {
            // No cookie consent has been give, can not show banners.
            return;
        }

        const banners = element.find("ins").filter((index, banner) => $(banner).data("width") < window.innerWidth);
        if (banners.length > 0) {
            const choice = Math.floor(Math.random() * banners.length);
            const banner = banners.eq(choice);
            this.element.addClass(banner.data("format"));
            replaceContent(this.element, banner.data("html"));
        }
    }
}
