import $ from "jquery";

import WidgetBase from "../../../rainbow/js/widgets/base";
import "../../scss/widgets/video-block.scss";
import {Lightbox} from "./photo-block";

export class VideoBlockWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.on({
            "click .thumbnail": e => {
                e.preventDefault();
                this.openLightbox();
            },
        });

        this.lightbox = new Lightbox(element);
        $(this.lightbox).on(
            "close", () => this.lightbox.content.find("iframe").remove()
        );

    }

    openLightbox() {
        const content = this.lightbox.content;
        const iframe = $("<iframe></iframe>");
        iframe.css({
            "width": "100%",
            "height": "385px",
        });

        iframe.attr("src", content.data("embedUrl"));
        iframe.attr("scrolling", "no");
        content.html(iframe);

        this.lightbox.open();
    }
}
