import $ from "jquery";

import WidgetBase from "../../../rainbow/js/widgets/base";
import "../../scss/widgets/photo-block.scss";

export class Lightbox {
    constructor(element) {
        this.element = element;
        this.body = $("body");
        this.container = $(".lightbox-container", element);
        this.container.appendTo(this.body);
        this.lightbox = $(".lightbox", this.container);
        this.content = $(".content", this.container);
        this.closeButton = $(".close", this.container);

        $(".close, .overlay", this.container).on("click", e => {
            e.preventDefault();
            this.close();
        });

        this.distanceFromTop = 40;
    }

    trigger(eventType, data) {
        const event = $.Event(eventType);
        $(this).triggerHandler(event, data);
        return event;
    }

    open() {
        this.trigger("open");
        this.adjustPosition();
        this.container.show();
        this.body.css("overflow", "hidden");
    }

    close() {
        this.trigger("close");
        this.container.hide();
        this.body.css("overflow", "auto");
    }

    adjustPosition() {
        const top = $(window).scrollTop() + this.distanceFromTop;
        this.lightbox.css("top", top + "px");
    }
}

export class PhotoBlockWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.on({
            "click .thumbnail": e => {
                e.preventDefault();
                this.openLightbox();
            },
        });

        this.image = element.find(".lightbox .content img");
        this.lightboxHeader = element.find(".lightbox .header");
        this.lightboxFooter = element.find(".lightbox .footer");
        this.lightbox = new Lightbox(element);
    }

    adjustSize() {
        const imageWidth = this.image[0].naturalWidth;
        const imageHeight = this.image[0].naturalHeight;
        const imageRatio = imageWidth / imageHeight;

        const horizontalPadding = 16;
        const verticalPadding = this.lightboxHeader.outerHeight() + this.lightboxFooter.outerHeight();

        const screenWidth = window.innerWidth - horizontalPadding;
        const screenHeight = window.innerHeight - verticalPadding - this.lightbox.distanceFromTop;
        const screenRatio = screenWidth / screenHeight;

        let width, height;
        if (imageWidth > screenWidth || imageHeight > screenHeight) {
            if (imageRatio > screenRatio) {
                width = screenWidth;
                height = screenWidth / imageRatio;
            } else {
                width = screenHeight * imageRatio;
                height = screenHeight;
            }
        } else {
            width = imageWidth;
            height = imageHeight;
        }

        this.image.width(width);
        this.image.height(height);
        this.lightbox.lightbox.width(width + horizontalPadding);
        this.lightbox.lightbox.height(height + verticalPadding);
    }

    openLightbox() {
        this.adjustSize();
        this.lightbox.open();
        this.adjustSize();
    }
}
