import $ from "jquery";
import _ from "lodash";

function updateFilter(query) {
    query = query.replace(/^\s+/, "").replace(/\s+$/, "");

    if (query === "") {
        $(".block.thema-list, .block.thema-list li").show();
        return;
    }

    $(".block.thema-list").each((index, block) => {
        let found = 0;
        $("li", block).each((index, item) => {
            item = $(item);
            if (item.text().includes(query)) {
                found += 1;
                item.show();
            } else {
                item.hide();
            }
        });
        $(block).toggle(found > 0);
    });
}

$(() => {
    const filterField = $(".thema-filter .search-field");
    filterField.on("keyup", _.debounce(() => updateFilter(filterField.val()), 200));
    filterField.keypress(function (event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    });

    const alphabetDropdown = $(".alphabet-dropdown select");
    alphabetDropdown.on("change", () => window.location.hash = "#" + alphabetDropdown.val());
});
