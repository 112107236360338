/*global grecaptcha:false */
import $ from "jquery";

import gettext from "../../../rainbow/js/gettext";
import {ContactWidget} from "../../../rainbow/js/widgets/contact";
import "../../scss/widgets/contact-form.scss";

export class ThemaContactWidget extends ContactWidget {
    constructor(element) {
        super(element);
    }

    // disable 'changeSubjects' of parent class
    changeSubjects() {}

    validateFields() {
        const fieldsAreValid = super.validateFields();
        const captchaIsValid = this.validateCaptcha();

        return fieldsAreValid && captchaIsValid;
    }

    validateCaptcha() {
        // grecaptcha comes from google.com/recaptcha/api.js
        const captcha = grecaptcha.getResponse();

        if (captcha === "") {
            this.errorsElement.append($("<div />").text(
                gettext("Please, enter the captcha."))
            );
            this.errorsElement.show();
            return false;
        }
        return true;
    }

    getSubmitUrl() {
        return "/contact-submit";
    }
}
